import {PreviewFormat} from '../types.ts';
import {Emoji} from '../emojis/types.ts';

export interface AiChunk {
    fieldKey: string;
    chunk: string;
    requestId: string;
}

export interface AiFeedbackItems {
    [key: string]: string;
}

export type AuthResult = {
    authTokens: AuthTokens;
    authError: AuthError;
    tokenInfo?: TokenInfo | null;
};

export type AuthTokens = {
    accessToken: string;
    refreshToken: string;
};

export type AuthTokensResponse = {
    token: string;
    refreshToken: string;
};

export type AuthError = {
    error: string;
    errorDescription: string;
};

export type TokenInfo = {
    loginProvider: LoginProvider;
    externalId: string;
    providerData: Record<string, string>;
};

export enum LoginProvider {
    Microsoft = 0,
    Google = 1,
    Facebook = 2,
    _3 = 3,
    Apple = 4
}

export enum UserStatus {
    NotRegistered = 0,
    Registered = 1,
    RegisteredBanned = 2
}

export enum ModerationStatus {
    Pending = 0,
    Accepted = 1,
    Failed = 2
}

export enum CollectionType {
    Curated = 0,
    Public = 1,
    Standard = 2,
    User = 3
}

export enum JournalSharing {
    GroupAdminOnly = 0,
    AllGroupMembers = 1,
    Public = 2
}

export enum Selection {
    None = 0,
    Single = 1,
    Multiple = 2
}

export enum AssetType {
    Any = 0,
    Model = 1,
    Audio = 2,
    Video = 3,
    Image = 4
}

export enum ProjectEntryType {
    Main = 0,
    Script = 1,
    File = 2,
    Folder = 3
}

export enum ContentSectionType {
    Markdown = 0,
    Code = 1,
    Html = 2,
    Video = 3,
    Picture = 4,
    Audio = 5,
    Header = 6,
    Model = 7,
    RichText = 8,
    Download = 9,
    Copy = 10,
    Embed = 11,
    Projects = 12
}

export enum VideoHostingService {
    YouTube = 0,
    HostedByMixiply = 1
}

export enum ViewMode {
    View = 0,
    Edit = 1
}

export enum GroupMemberInfoFieldType {
    Text = 0,
    MultiLineText = 1,
    Date = 2,
    Time = 3,
    Number = 4,
    Email = 5,
    CheckBox = 6,
    SelectList = 7,
    RadioButtonList = 8
}

export enum GroupMemberInfoFieldEditableBy {
    None = 0,
    Member = 1,
    Admin = 2
}

export enum UserCollectionType {
    Admin = 0,
    Contributor = 1,
    Viewer = 2
}

export enum MembershipStatus {
    Member = 0,
    Invited = 1,
    Requested = 2,
    InviteIgnored = 3,
    RequestIgnored = 4
}

export enum PostType {
    Post = 0,
    Reply = 1
}

export enum NotificationType {
    NewPost = 0,
    PostReply = 1,
    ProjectAdded = 2,
    PostReaction = 3,
    Mention = 4,
    Invite = 5,
    RequestToJoin = 6
}

export type CollectionModel = {
    id: string | null;
    name: string;
    description: string;
    collectionType: CollectionType;
    public: boolean;
    containerName: string;
    thumbnail: string;
    contributors: string[];
    viewers: string[];
    groupMemberEditable: boolean | null;
    archived: boolean;
    loggedInUserUserCollectionType: UserCollectionType | null;
    loggedInUserMembershipStatus: MembershipStatus | null;
};

export type CreateCollectionResult = {
    id: string;
    containerName: string;
};

export type CollectionInfoModel = {
    id: string | null;
    name: string;
    description: string;
    collectionType: CollectionType;
    public: boolean;
    containerName: string;
    thumbnail: string;
    homeMixId: string | null;
    memberCount: number;
    mixesCount: number;
    archived: boolean;
    loggedInUserUserCollectionType: UserCollectionType | null;
    loggedInUserMembershipStatus: MembershipStatus | null;
};

export type ChannelInfoModel = {
    id: string | null;
    collectionId: string;
    createdOnUtc: string;
    name: string;
    topic: string;
    isDefault: boolean;
    isDeleted: boolean;
};

export type CollectionJoinCodeModel = {
    id: string | null;
    collectionId: string;
    viewer: string | null;
    contributor: string | null;
    admin: string | null;
};

export type CollectionMixesResult = {
    shared: MixModel[];
    private: MixModel[];
};

export enum ProjectFormat {
    Web,
    ArVr
}

export type MixModel = {
    id: string | null;
    name: string;
    description: string;
    tags: string[];
    customUrl: string;
    projectFormat: ProjectFormat;
    rank: number | null;
    rankOverride: number | null;
    revision: number;
    userId: string | null;
    nickname: string;
    userName: string;
    userThumbnail: string;
    userContainerName: string;
    includes: string;
    thumbnail: string;
    thumbnailHasChanged: boolean;
    mixScript: string;
    content: ContentPackageModel;
    moderationStatus: ModerationStatus;
    moderationMessage: string;
    blocked: boolean;
    archived: boolean;
    templateId: string | null;
    isTemplate: boolean;
    createdFromTemplateId: string | null;
    projectEntries: ProjectEntryModel[];
    collections: CollectionModel[];
    editors: MixEditorModel[];
    tabs: ProjectTabs | null;
};

export interface ProjectTabs {
    tab1: ProjectTab;
    tab2: ProjectTab;
    tab3: ProjectTab;
    tab4: ProjectTab;
    tab5: ProjectTab;
}

export interface ProjectTab {
    type: ProjectTabType;
    enabled: boolean;
    src: string | null;
    title: string | null;
    iconClass: string | null;
}

export enum ProjectDataIndex {
    Zero = 0,
    Tab1 = 1,
    Tab2 = 2,
    Tab3 = 3,
    Tab4 = 4,
    Tab5 = 5
}

export enum ProjectTabType {
    Canvas,
    Scene,
    Content,
    Website
}

export type ContentPackageModel = {
    id?: string | null;
    sections: ContentSectionModel[];
};

export type ContentSectionModel = {
    id?: string | null;
    ordinalPosition: number;
    type: ContentSectionType;
    content?: string | null;
    fileName?: string | null;
    altText?: string | null;
    service?: VideoHostingService | null;
    videoId?: string | null;
    attribution?: UserInfo | null;
    centered?: boolean;
    templateKey?: string | null;
};

export type ContentPackageData = Record<string, ContentSectionModel>;

export type TemplateContentConfig = {
    templateFields: TemplateFieldModel[];
};

export type ElementIdAndContentSections = {
    elementId: string;
    section: ContentSectionModel;
}[];

export type UserInfo = {
    thumbnail: string;
    nickname: string;
    name: string;
    containerName: string;
    userCollectionType: UserCollectionType | null;
    membershipStatus: MembershipStatus | null;
};

export type MixTemplateModel = {
    id: string;
    name: string;
    description: string;
    customUrl: string | null;
    ordinalPosition: number;
    assetSelection: AssetSelectionModel;
    mixId: string;
    mix: MixTemplateMixModel;
    fields: TemplateFieldModel[];
    aiPrompt: string | null;
    aiTemplateFeedbackPrompt: string | null;
    aiJournalEntryPrompt: string | null;
    aiThumbnailPrompt: string | null;
    aiTemplateFeedbackEnabled: boolean | null;
    mobileFriendly: boolean;
    canRunAsProject: boolean;
    mixIdString: string;
};

export type MixTemplateMixModel = {
    id: string;
    customUrl: string | null;
    name: string;
    description: string;
    nickname: string;
    thumbnail: string;
    tags: string[];
};

export type AssetSelectionModel = {
    selection: Selection;
    type: AssetType;
    explanation: string;
};

export type TemplateFieldModel = {
    key: string;
    name: string;
    type: TemplateFieldType;
    defaultValue: string | null;
};

export enum TemplateFieldType {
    Text = 0,
    Color = 1,
    Boolean = 2,
    Textarea = 3,
    Options = 4
}

export type ProjectEntryModel = {
    id: string | null;
    archived: boolean;
    projectEntryType: ProjectEntryType;
    path: string;
    uri: string;
    content: string;
};

export type MixEditorModel = {
    userId: string;
    nickname: string;
    name: string;
    containerName: string;
    thumbnail: string;
};

export type Option<TValue> = {
    value: TValue;
    text: string;
    disabled: boolean | null;
};

export type EmptyResult = NonNullable<unknown>;

export interface IGroupMemberInfoAnswer {
    stringAnswer: string | null;
    booleanAnswer: boolean | null;
    numberAnswer: number | null;
    dateAnswer: Date | null;
    timeAnswer: string | null;
}

export interface IGroupMemberField {
    id: string | null;
    introContent: string;
    fieldType: GroupMemberInfoFieldType;
    label: string;
    options: string[];
    maxLength: number | null;
}

export interface IOrdinalPosition {
    id: string | null;
    ordinalPosition: number;
}

export type GroupMemberInfoFieldModel = IGroupMemberField &
    IOrdinalPosition & {
        collectionId: string;
        editableBy: GroupMemberInfoFieldEditableBy;
    };

export type GroupMembersModel = {
    members: GroupMemberModel[];
    owners: GroupMemberModel[];
    contributors: GroupMemberModel[];
    viewers: GroupMemberModel[];
    pending: GroupMemberModel[];
};

export type GroupMemberModel = {
    userId: string;
    nickname: string;
    name: string;
    containerName: string;
    thumbnail: string;
    userCollectionType: UserCollectionType;
    membershipStatus: MembershipStatus;
    groupMemberInfoAnswers: GroupMemberInfoAnswerModel[];
};

export type GroupMemberInfoAnswerModel = IGroupMemberInfoAnswer & {
    id: string | null;
    userId: string;
    collectionId: string;
    groupMemberInfoFieldId: string;
    createdOnUtc: string;
    updatedOnUtc: string;
};

export type JournalEntryModel = {
    id: string | null;
    content: ContentPackageModel;
    mixId: string;
    userId: string | null;
    createdOnUtc: string | null;
    isDeleted: boolean;
    autoGenerated: boolean;
};

export type JournalSharingResult = {
    journalSharing: JournalSharing;
    sharedWithLoggedInUser: boolean;
};

export type UpsertJournalEntryResult = {
    contentPackageId: string;
    id: string;
};

export type PagedResult<TItem> = {
    page: number;
    perPage: number;
    count: number;
    total: number;
    isFirst: boolean;
    isLast: boolean;
    hasNext: boolean;
    hasPrevious: boolean;
    totalPages: number;
    items: TItem[];
};

export type LogModel = {
    id: string;
    application: string;
    createdOnUtc: string;
    level: string;
    url: string;
    action: string;
    message: string;
    logger: string;
    callsite: string;
    exception: string;
};

export type Pagination = {
    page: number;
    perPage: number;
};

export type UpsertResult = {
    found: boolean;
    id: string | null;
};

export type FlagModel = {
    mixId: string | null;
    reason: string;
    moderatorComment: string;
    flaggedContentStatus: FlaggedContentStatus;
};

export enum FlaggedContentStatus {
    Raised = 0,
    Dismissed = 1,
    Blocked = 2
}

export type MixesResult = {
    mixes: PagedResult<MixModel>;
    hasResults: boolean;
};

export enum MixType {
    Projects = 0,
    Templates = 1,
    All = 2
}

export enum MatchOperator {
    Any = 0,
    All = 1
}

export type MixUrls = {
    qrCodeUrl: string;
    manifestUrl: string;
};

export type IdResult = {
    id: string;
};

export type RemixMixResult = IdResult & {
    tab: string | null;
};

export type TemplateReplacement = {
    replace: string;
    with: string;
    type: TemplateReplacementType;
};

export enum TemplateReplacementType {
    Script = 0,
    Content = 1
}

export type UpsertMixResult = {
    found: boolean;
    id: string | null;
    customUrl: string;
};

export type MyGroupMemberModel = {
    userId: string;
    nickname: string;
    name: string;
    containerName: string;
    thumbnail: string;
    groups: string[];
    displayName: string;
};

export type MyGroupMemberFilters = {
    name: string | null;
    nickname: string | null;
    userCollectionType: UserCollectionType | null;
    groupIds: string[];
    groupMemberInfoFilters: GroupMemberInfoFilter[];
};

export type GroupMemberInfoFilter = IGroupMemberInfoAnswer & IGroupMemberField;

export type MyGroupMemberInfoField = {
    label: string;
    fieldType: GroupMemberInfoFieldType;
    options: string | null;
};

export type NotificationModel = {
    id: string;
    createdOnUtc: string;
    initiatedBy: UserInfo;
    notificationType: NotificationType;
    viewed: boolean;
    viewedOnUtc: string | null;
    mix: MixModel;
    postId: string | null;
    postCreatedOnUtc: string | null;
    parentPostId: string | null;
    parentPostIdCreatedOnUtc: string | null;
    collectionId: string | null;
    collectionThumbnail: string;
    collectionName: string;
    collectionContainerName: string;
    channelId: string | null;
    channelName: string;
    data: string;
};

export enum Period {
    Newer = 0,
    Older = 1,
    MostRecent = 2,
    AllTime = 3
}

export type PlanModel = {
    id: string | null;
    name: string;
    detail: PlanDetailModel;
    paymentInfo: PlanPaymentInfoModel;
    uploadLimit: number | null;
    archived: boolean;
    hasCollections: boolean;
    ordinalPosition: number;
    dailyAiImageCreationLimit: number;
};

export type PlanDetailModel = {
    description: string;
};

export type PlanPaymentInfoModel = NonNullable<unknown>;

export type PostModel = {
    content: string;
    collectionId: string;
    channelId: string;
    postType: PostType;
    userId: string;
    replyToId: string | null;
};

export type DisplayPostModel = {
    id: string;
    content: string;
    collectionId: string;
    channelId: string;
    userId: string;
    createdOnUtc: string;
    user: UserInfo;
    parentId: string | null;
    postType: PostType;
    replies: DisplayPostModel[] | null;
    countReplies: number;
    reactionsCount: number;
    reactionSummary: string;
    showDateSeparator: boolean;
};

export type PostReactionModel = {
    reaction: string;
    userNickname: string;
    userName: string;
    userThumbnail: string;
    createdOnUtc: string;
};

export type PostReactionSummaryModel = {
    postId: string;
    parentPostId: string | null;
    reactionSummary: string;
    reactionsCount: number;
};

export type RepliesResult = {
    replies: DisplayPostModel[] | null;
    countReplies: number;
};

export type PostsResult = {
    posts: DisplayPostModel[];
    readAtUtc: string;
};

export interface IOrdinalPosition {
    id: string | null;
    ordinalPosition: number;
}

export type SiteContentModel = {
    id: string | null;
    slug: string;
    title: string;
    tags: string[];
    publishStatus: PublishStatus;
    content: ContentPackageModel;
    breadcrumb: BreadcrumbLinkModel[];
    selfAndSiblings: BreadcrumbLinkModel[];
    results: BreadcrumbLinkModel[];
    inToc: boolean | null;
};

export type PromptModel = {
    name: string;
    content: string;
    fields: TemplateFieldModel[];
};

export enum PublishStatus {
    Draft = 0,
    Published = 1,
    Archived = 2
}

export type BreadcrumbModel = {
    title: string;
    href: string;
};

export type BreadcrumbLinkModel = BreadcrumbModel & {
    active: boolean;
    children: BreadcrumbLinkModel[];
};

export type TableOfContentsEntry = {
    siteContentId: string | null;
    slug: string;
    isUrl: boolean;
    title: string;
    entries: TableOfContentsEntry[];
};

export type UpdateThumbnailResult = {
    thumbnail: string;
    unauthorized: boolean;
};

export type DirectoryModel = {
    name: string;
    path: string;
    directories: DirectoryModel[];
    files: FileDataModel[];
    isRoot: boolean;
    isNew: boolean;
    length: number;
};

export type FileDataModel = IAsset & {
    dirPath: string[];
    path: string;
    name: string;
    originalFileName: string;
};

export interface IAsset {
    uri: string;
    fileName: string;
    extension: string;
    length: number | null;
    contentType: string;
    canCreateMix: boolean | null;
    canViewInVR: boolean | null;
    userId: string | null;
    previewFormat: PreviewFormat;
}

export type RenameFileResult = {
    originalUri: string;
    newUri: string;
    newName: string;
};

export type UploadResult = FileDataModel & {
    blobName: string;
    uploadedOnUtc: string;
};

export type ActivityModel = {
    id: string;
    userId: string;
    activityType: UserActivityType;
    createdOnUtc: string;
    journalEntryId: string | null;
    contentPackageId: string | null;
    content: ContentPackageModel;
    mixId: string | null;
    project: MixModel;
    post: PostActivityModel;
    assetId: string | null;
    asset: AssetModel;
    autoGenerated: boolean;
};

export enum UserActivityType {
    NewPost = 0,
    PostReply = 1,
    PostReaction = 2,
    Mention = 3,
    JournalEntry = 4,
    AssetUploaded = 5
}

export type PostActivityModel = {
    collectionThumbnail: string;
    collectionName: string;
    collectionContainerName: string;
    channelName: string;
    postId: string | null;
    parentPostId: string | null;
    collectionId: string | null;
    channelId: string | null;
    data: string;
    postUser: UserInfo;
    parentPostUser: UserInfo;
};

export type AssetModel = IAsset & {
    id: string;
    containerName: string;
    name: string;
    createdOnUtc: string;
    lastModifiedOnUtc: string;
};

export enum UserActivitiesFor {
    Profile = 0,
    Group = 1,
    Members = 2
}

export type UpdateUserResult = {
    found: boolean;
    id: string | null;
};

export type CollectionGroupMemberInfoModel = {
    collectionId: string;
    collectionName: string;
    answersAndFields: GroupMemberInfoAnswerFieldModel[];
};

export type GroupMemberInfoAnswerFieldModel = {
    answer: GroupMemberInfoAnswerModel;
    field: GroupMemberInfoFieldModel;
};

export type UserProfileEmojis = {
    skinTone: string;
    recentlyUsed: Emoji[];
};

export type UserDetailsModel = {
    id: string;
    loginProvider: LoginProvider;
    externalId: string;
    nickname: string;
    name: string;
    containerName: string;
    thumbnail: string;
    email: string;
    userStatus: UserStatus;
    moderationStatus: ModerationStatus;
    moderationMessage: string;
    uploadLimit: number | null;
    hasCollections: boolean;
    roles: string[];
    planId: string | null;
    plan: string;
    createdOnUtc: string | null;
    lastAuthenticatedOnUtc: string | null;
};

export type UserProfileModel = {
    id: string;
    nickname: string;
    name: string;
    thumbnail: string;
    email: string;
    alternativeEmail: string;
    moderationStatus: ModerationStatus;
    moderationMessage: string;
    roles: string[];
    bio: string;
    userContainerName: string;
};

export type AddPendingGroupMemberResponse = {
    membershipStatus: MembershipStatus;
    message: string;
};

export type ApiDocumentModel = {
    id: string | null;
    apiClassDefinition: ApiClassDefinition;
};

export interface IName {
    name: MarkedString;
}

export type ApiClassDefinition = IName & {
    description: ContentPackageModel;
    example: ContentPackageModel;
    properties: ApiPropertyDefinition[];
    methods: ApiMethodDefinition[];
    status: PublishStatus;
};

export type ApiPropertyDefinition = IName & {
    description: ContentPackageModel;
    example: ContentPackageModel;
    isStatic: boolean | null;
    returnType: ApiTypeDefinition;
    readOnly: boolean;
};

export type ApiTypeDefinition = {
    name: string;
    type: string;
    description: string;
    optional: boolean;
};

export type MarkedString = {
    value: string;
    isMarked: boolean;
    fragments: MarkedFragment[];
};

export type MarkedFragment = {
    value: string;
    marked: boolean;
};

export type ApiMethodDefinition = IName & {
    description: ContentPackageModel;
    example: ContentPackageModel;
    isStatic: boolean | null;
    arguments: ApiTypeDefinition[];
    returnType: ApiTypeDefinition;
};

export type EntityResult = {
    id: string | null;
    notFound: boolean;
    errors: Record<string, string>;
};

export type ModeratorResponse = {
    result: string;
    statusCode: number;
};

export type ProjectSceneScriptAndHtmlModel = {
    mixId: string;
    dataIndex: ProjectDataIndex;
    sceneHtml: string;
    sceneScript: string;
    syncToken: string;
};

export type Expert = {
    id: string;
    name: string;
    description: string;
    systemPrompt: string;
};
